export const isDebugMode = Boolean(process.env.DEBUG);
export const environment = process.env.NODE_ENV;

export const DEFAULT_CURRENCY = 'NOK';

export const ABROAD_RULES_ON_HELPCENTER = 'https://hjelpesenter.finn.no/hc/no/articles/22618601225490';
export const TJVR_RULES_ON_HELPCENTER = 'https://hjelpesenter.finn.no/hc/no/articles/22619198047890';
export const TJVR_INSURANCE_TERMS_ON_HELPCENTER = 'https://hjelpesenter.finn.no/hc/no/article_attachments/22619227668242';
export const WELCOME_TO_NEW_FHH_ON_HELPCENTER = 'https://hjelpesenter.finn.no/hc/no/articles/22595768176146';
export const PAYMENT_AND_COMMUNCATION_ON_HELPCENTER = 'https://hjelpesenter.finn.no/hc/no/articles/22595584862738';
